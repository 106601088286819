//Variables
$color-main: #212529;
$color-second: #3e4144;
$color-font: #3e4144;
$color-carousel: #ffff;
$font-main: 'Tangerine', cursive;
$font-second: 'Abel', sans-serif;

.contenedor-main{

    .contenedor-tarjeta{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        margin-top: 100px;
    
        .tarjeta-link{
            text-decoration: none;
    
            margin-right: 40px;
    
            .portada{
                height: 200px;
    
                object-fit: cover;
    
            }
    
            .tarjeta{
                width: 250px;
                margin-top: 40px;
        
                .descripcion{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    position: relative;
        
                    .titulo{
                        text-align: center;
                        font-family: $font-main;
                        font-size: 30px;
                        font-weight: 600;
        
                        color: $color-font;
                    }
        
                    .contenedor{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .texto{
                            font-family: $font-second;
                            font-size: 20px;
                        }

                        .opcion{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;

                            border-style: none;
                            background-color: #ffff;
                            
                            width: 25px;
                            height: 30px;
                            padding: 0;
                            margin-right: -5px;

                            img{
                                width: 70%;
                            }
                        }

                        .opciones{
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            align-items: center;

                            font-family: $font-second;

                            position: absolute;
                            right: 35px;
                            bottom: -15px;

                            border: 1px solid #3e4144;
                            border-radius: 5px;
                            background-color: #3e4144;

                            padding: 5px;
                            padding-left: 15px;

                            width: 130px;

                            a{
                                margin: 4px;
                                width: 100%;

                                color: #ffff;
                                text-decoration: none;

                                font-size: 19px;

                                //border-bottom: 1px solid #bdc4cc;
                            }
                        }

                        .cerrado{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:769px){
    
}

@media (max-width:551px){
    .contenedor-main{

        .contenedor-tarjeta{

            .tarjeta-link{
                margin-right: 0px;
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width:426px){
    
}

@media (max-width:376px){
    
}

@media (max-width:321px){

    .menu-eventos{
        width: 100%;

        h3{
            font-size: 30px;
        }
    }
}
$color-main: #212529;
$color-second: #3e4144;
$color-font: #3e4144;
$color-carousel: #ffff;
$font-main: 'Tangerine', cursive;
$font-second: 'Abel', sans-serif;

.invitados{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    margin-top: 70px;

    .formulario{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        width: 400px;
        padding: 12px 10px;
        margin: 10px 15px;

        border-radius: 10px;

        background-color: #ffff;

        font-family: $font-second;

        .titulo{
            font-size: 26px;
            text-align: center;

            width: 100%;
            height: 100%;
        }

        .abierto{
            display: block;

            width: 100%;

            .captura{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;

                padding: 10px;
                margin-top: 5px;

                .dato{
                    width: 100%;
                    margin-bottom: 10px;

                    label{
                        font-size: 17px;
                    }
                }

                .enviar{
                    margin-top: 10px;
                    width: 100%;
                    height: 38px;

                    border-radius: 5px;
                    border: none;

                    color: #ffff;
                    background-color: #212529;

                    font-size: 17px;
                }
            }
        }

        .cerrado{
            display: none;
        }
    }

    .lista{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        background-color: #ffff;

        border-radius: 10px;

        padding: 12px;
        margin: 20px 15px;
        width: 400px;

        font-family: $font-second;
        text-align: center;

        .titulo{
            font-size: 26px;
            text-align: center;

            width: 100%;
            height: 100%;
        }

        .contenido{
            display: flex;
            flex-direction: column;

            width: 100%;
            max-height: 72vh;

            overflow-y: scroll;

            margin-top: 15px;

            .seccion{
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                align-content: center;

                padding: 15px;

                border-bottom: 1px solid #3e4144;

                .invitado{
                    width: 140px;
                    font-size: 17px;
                }

                .boton{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    border-style: none;
                    border-radius: 5px;
                    background-color: lightblue;

                    width: 35px;
                    height: 35px;
                    padding: 7px;
                    margin: 0 2px;

                    .icono{
                        width: 100%;
                    }
                }

                .copiado{
                    background-color: lightgreen;
                }

                .eliminar{
                    background-color: lightcoral;
                }

                .alerta{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
        
                    width: 100vw;
                    height: 100vh;
        
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 90;
        
                    background-color: rgba(0, 0, 0, .5);

                    .contenedor{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: center;

                        min-width: 300px;
                        max-width: 400px;
                        height: 250px;
                        margin: 15px;
                        padding: 15px;

                        border-radius: 10px;

                        background-color: #ffff;

                        .mensaje{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            span{
                                font-size: 20px;
                            }
                        }

                        .botones{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;
                            align-items: center;

                            width: 100%;

                            .eliminar{
                                background-color: lightcoral;

                                border-style: none;
                                border-radius: 5px;

                                width: 65px;
                                height: 35px;
                                padding: 5px;
                            }

                            .cancelar{
                                background-color: lightblue;
                                border-style: none;
                                border-radius: 5px;

                                width: 65px;
                                height: 35px;
                                padding: 5px;
                            }
                        }

                    }    
    
                }
            }

        }

        .cerrado{
            display: none;
        }
    }
}

.gestion{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    padding: 80px 10px 10px;

    .mesa{
        max-width: 350px;
        min-width: 300px;
        height: 100%;

        margin: 25px 15px 10px;
        
        background-color: white;

        border-radius: 10px;

        .encabezado{
            border-bottom: 2px solid lightgrey;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-content: center;

            font-family: $font-second;
            font-size: 23px;

            padding: 15px;

            .icono{
                width: 20px;

                transform: rotate(180deg)
            }
        }

        .contenido{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            flex-wrap: wrap;

            padding: 10px;
            width: 100%;

            border-bottom: 2px solid lightgray;

            font-family: $font-second;
            font-size: 20px;

            .invitado{
                width: 100%;
                margin: 10px;

                text-align: center;
            }

            .asistencia{
                margin-bottom: 5px;

                .icono{
                    width: 20px;
                }
            }
        }
    }

    .completa{
        border: 4px solid lightgreen;
    }

    .pendiente{
        border: 4px solid lightblue;
    }
}